
var MYTOOL = {

    isEmpty(value)
    {
        if(value === undefined || value === null || value === "")
        {
            return true
        }
        return false
    },

    toInt(value,defaultValue)
    {
        value = parseInt(value)
       if(isNaN(value))
        {
            if(defaultValue == undefined)
            {
                return 0;
            }
            return defaultValue;
        }
        return value;
    },

    toFloat(value,defaultValue)
    {
        value = parseFloat(value)
       if(isNaN(value))
        {
            if(defaultValue == undefined)
            {
                return 0;
            }
            return defaultValue;
        }
        return value;
    },

    toNumber(v,c)
    {
        v = Number(v)
        v = v.toFixed(c)
        v = Number(v)
        return v
    },

    //将对象转换成url参数 {aaa:111,bbb:222}  转换 aaa=111&bbb=222
    toQueryString(obj) {
        let arr = [];
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
          }
        }
        return arr.join('&');
    },

    
    isArray(variable) {
        if(MYTOOL.isEmpty(variable))
            return false;
        return typeof variable === 'object' && Array.isArray(variable);
    },


    toArray(values,splitChat)
    {
        if(splitChat == undefined)
            splitChat = " "

        if(MYTOOL.isArray(values))
            return values;
        else if(typeof values == "string")
        {
            return values.split(splitChat)
        }
        else if(typeof values == "number")
        {
            return [values]
        }
        else if(typeof values == "boolean")
        {
            return [values]
        }
        else if(typeof values == "object")
        {
            return Object.values(values)
        }
    },

    arrayToString(arr,joinChat)
    {
        if(joinChat == undefined)
            joinChat = " "
        if(!MYTOOL.isArray(arr))
            return "";
        return arr.join(joinChat);
    },

    toString(value,joinChat)
    {
        if(joinChat == undefined)
            joinChat = " "
        if(MYTOOL.isEmpty(value))
            return ""
        if(MYTOOL.isArray(value))
        {
            return value.join(joinChat);
        }
        else if(typeof value == "object")
            return JSON.stringify(value)
        else
           return value + "" 
    },

    getValueByFeildFormat(data,field)
    {
       // console.log("getValueByFeildFormat????????",data,field)
       if(MYTOOL.isEmpty(field))
       {
            return 0
       }
        let arr = field.split(".")
     //   console.log("getValueByFeildFormat????????" + arr.length)
        if(arr.length <= 1)
            return data[field]
        else
        {
            let start = data[arr[0]]
            if(start)
            {
                for(let i = 1;i < arr.length; i++ )
                {
                    if(!start)
                        break;
                    start = start[arr[i]]
                }
            }
            return start;
        }
    },



    uint8ArrayToString(u8a) {
        let dataStr = "";
        for(let i=0;i<u8a.length;i++) {
            dataStr += String.fromCharCode(u8a[i])
        }
        return dataStr;
    },

    //将utf-8编码的字符转换为gbk编码的字符
    utf8toGbk(word) {
        let encoder = new TextEncoder('gbk');
        let decoder = new TextDecoder('utf-8');
        let data = encoder.encode(word);
        let result = decoder.decode(data);
        return result;
    },

    //将gbk编码的字符转换为utf-8编码的字符
    gbktoUtf8(word) {
        let encoder = new TextEncoder('utf-8');
        let decoder = new TextDecoder('gbk');
        let data = encoder.encode(word);
        let result = decoder.decode(data);
        return result;
    },



    isUTF8(bytes) {
        var i = 0;
        while (i < bytes.length) {
            if ((// ASCII
                bytes[i] == 0x09 ||
                bytes[i] == 0x0A ||
                bytes[i] == 0x0D ||
                (0x20 <= bytes[i] && bytes[i] <= 0x7E)
            )
            ) {
                i += 1;
                continue;
            }
      
            if ((// non-overlong 2-byte
                (0xC2 <= bytes[i] && bytes[i] <= 0xDF) &&
                (0x80 <= bytes[i + 1] && bytes[i + 1] <= 0xBF)
            )
            ) {
                i += 2;
                continue;
            }
      
            if ((// excluding overlongs
                bytes[i] == 0xE0 &&
                (0xA0 <= bytes[i + 1] && bytes[i + 1] <= 0xBF) &&
                (0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF)
            ) ||
                (// straight 3-byte
                    ((0xE1 <= bytes[i] && bytes[i] <= 0xEC) ||
                        bytes[i] == 0xEE ||
                        bytes[i] == 0xEF) &&
                    (0x80 <= bytes[i + 1] && bytes[i + 1] <= 0xBF) &&
                    (0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF)
                ) ||
                (// excluding surrogates
                    bytes[i] == 0xED &&
                    (0x80 <= bytes[i + 1] && bytes[i + 1] <= 0x9F) &&
                    (0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF)
                )
            ) {
                i += 3;
                continue;
            }
      
            if ((// planes 1-3
                bytes[i] == 0xF0 &&
                (0x90 <= bytes[i + 1] && bytes[i + 1] <= 0xBF) &&
                (0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF) &&
                (0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xBF)
            ) ||
                (// planes 4-15
                    (0xF1 <= bytes[i] && bytes[i] <= 0xF3) &&
                    (0x80 <= bytes[i + 1] && bytes[i + 1] <= 0xBF) &&
                    (0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF) &&
                    (0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xBF)
                ) ||
                (// plane 16
                    bytes[i] == 0xF4 &&
                    (0x80 <= bytes[i + 1] && bytes[i + 1] <= 0x8F) &&
                    (0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xBF) &&
                    (0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xBF)
                )
            ) {
                i += 4;
                continue;
            }
            return false;
        }
        return true;
      },

    resetWebPackAssetsPath(assetsPath)
    {
        assetsPath = assetsPath.replace("@/assets/","")
         return assetsPath;
    },

    JSONParse(string)
    {
        let obj = null
        try{
            obj = JSON.parse(string)
        }
        catch(err)
        {
            console.log(err)
            obj = null
        }
        return obj;
    },


    arrNumberToArrBoolean(arr)
    {
        let ret = []
        arr.forEach(element => {
            if(element == 0)
                ret.push(false)
            else
                ret.push(true)
        });
        return ret
    },

    arrBooleanToArrNumber(arr)
    {
        let ret = []
        arr.forEach(element => {
            if(element == false)
                ret.push(0)
            else
                ret.push(1)
        });
        return ret
    },

    getArrayById(arr,id)
    {
        if(MYTOOL.isEmpty(arr))
            return undefined;
        let find = null;
        arr.forEach(element => {
            if(typeof element.id == "number")
            {
                if(Number(id) ==  element.id)
                {
                    find = element
                }
            }
            else if(typeof element.id == "string")
            {
                if(String(id) ==  element.id)
                {
                    find = element
                }
            }
        });
        return find;
    },

    getArrayLabelById(arr,id)
    {
        let obj = MYTOOL.getArrayById(arr,id);
        if(obj == null)
            return ""
        return obj.label
    },


    getLabelByStrValues(info,values,splitChat)
    {
        if(MYTOOL.isEmpty(values))
            return ""
        if(typeof values != "string")
            return ""

        if(splitChat == undefined)
            splitChat = " "

        let labels = []
        let ids = values.split(splitChat)
        ids.forEach(element => {
            let obj = MYTOOL.getArrayById(info,element);
            if(obj == null)
                return ""
            labels.push(obj.label)
        });
 
        return labels.join(splitChat)
    },
    split(str,c)
    {
        if(MYTOOL.isEmpty(str))
            return []
        return str.split(c)
    },
    //替换
    replace(src,r,t)
	{
		return src.replace(new RegExp(r,"gm"), t);
	},
    //将多个空格变成一个
    collapseSpaces(str) {
        return str.replace(/\s+/g, ' ');
      },

    findValues(values,v)
    {
        let find = false
        values.forEach(element => {
            if(element == v)
                find = true
        });
        return find
    },

    removeValues(values,v)
    {
        for(let i = values.length; i >= 0; i--)
        {
            if(values[i] == v)
            {
                values.splice(i,1);
            }
        }
    
    },


    defineObject(obj,name)
    {
        if(!MYTOOL.isEmpty(obj[name]))
            return;
        obj[name] = {}
    },

    defineArray(obj,name)
    {
        if(!MYTOOL.isEmpty(obj[name]))
            return;
        obj[name] = []
    },

    checkNumber(value)
    {
        let req =  /^[0-9]+$/
        if(value == "" || !req.test(value))
        {
          return false;
        }
        return true;
    },

    checkNomalChat(value) //数字 字母 下划线
    {
        let req =  /^[0-9a-zA-Z_]+$/
        if(value == "" || !req.test(value))
        {
          return false;
        }
        return true;
    },
    
    checkPassword(value) //数字 字母 下划线 小于16位
    {
        let req =  /^[0-9a-zA-Z_]+$/
        if(value == "" || value.length > 16 || !req.test(value))
        {
          return false;
        }
        return true;
    },

    checkTelNumber(value)
    {
        let telReq = /^1[3-9][0-9]{9}$/
        if(value == "" || !telReq.test(value))
        {
          return false;
        }
        return true;
    },

    checkEMail(value)
    {
        var emailReq = /^w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/i
        if(value == "" || !emailReq.test(value))
        {
          return false;
        }
        return true;
    },
    
    stringToHex(str) {
        const buf = Buffer.from(str, 'utf8');
        return buf.toString('hex');
    },
    hexToString(str) {
        const buf = Buffer.from(str, 'hex');
        return buf.toString('utf8');
    },
    hexToUInt64(str) {
        const buf = Buffer.from(str, 'hex');
        return buf.readBigUInt64LE(0);
    },
    hexToInt64(str) {
        const buf = Buffer.from(str, 'hex');
        return buf.readBigInt64LE(0);
    },

    hexToUInt32(str) {
        const buf = Buffer.from(str, 'hex');
        return buf.readUInt32LE(0);
    },

    hexToInt32(str) {
        const buf = Buffer.from(str, 'hex');
        return buf.readInt32LE(0);
    },

    hexToUInt16(str) {
        const buf = Buffer.from(str, 'hex');
        return buf.readUInt16BE(0);
    },

    hexToInt16(str) {
        const buf = Buffer.from(str, 'hex');
        return buf.readInt16LE(0);
    },

    buffToString(chunk) {
        let _end = chunk.indexOf(0);
        if (_end == -1) {
            return chunk.toString();
        }
        else
            return chunk.slice(0, _end).toString();
    },

    getRandom: function (min, max) {
        if (min > max) {
            let t = min;
            min = max;
            max = t;
        }
        return min + Math.floor(Math.random() * (max - min + 1));


    },

    GetRandomStrCode(nLen) {
        let code = ""
        let szNumCode = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
            97, 98, 99, 100, 101, 102, 103, 104, 105, 106,
            107, 108, 109, 110, 111, 112, 113, 114, 115, 116,
            117, 118, 119, 120, 121, 122];
        for (let i = 0; i < nLen; i++) {
            code += String.fromCharCode(szNumCode[MYTOOL.getRandom(0, szNumCode.length - 1)]);
        }
        return code;
    },

    getBracketString(str, s1, s2) {
        let index1 = str.indexOf(s1)
        let index2 = str.indexOf(s2)
        str = str.substring(index1 + 1, index2)
        return str
    },

    padZero(number,size)
    {
       if(MYTOOL.isEmpty(size))
       {
            size = 8;
       }
       let _str = String(number)
       return _str.padStart(size,'0');
    },


    getAge(str){
        var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})/);
        if(r==null)return   false;
    
        var d= new Date(r[1],r[3]-1,r[4]);
        var returnStr = "输入的日期格式错误！";
    
        if(d.getFullYear()==r[1]&&(d.getMonth()+1)==r[3]&&d.getDate()==r[4]){
    
            var date = new Date();
            var yearNow = date.getFullYear();
            var monthNow = date.getMonth() + 1;
            var dayNow = date.getDate();
    
            var largeMonths = [1,3,5,7,8,10,12], //大月， 用于计算天，只在年月都为零时，天数有效
                lastMonth = monthNow -1>0?monthNow-1:12,  // 上一个月的月份
                isLeapYear = false, // 是否是闰年
                daysOFMonth = 0;    // 当前日期的上一个月多少天
    
            if((yearNow%4===0&&yearNow%100!==0)||yearNow%400===0){  // 是否闰年， 用于计算天，只在年月都为零时，天数有效
                isLeapYear = true;
            }
    
            if(largeMonths.indexOf(lastMonth)>-1){
                daysOFMonth = 31;
            }else if(lastMonth===2){
                if(isLeapYear){
                    daysOFMonth = 29;
                }else{
                    daysOFMonth = 28;
                }
            }else{
                daysOFMonth = 30;
            }
    
            var Y = yearNow - parseInt(r[1]);
            var M = monthNow - parseInt(r[3]);
            var D = dayNow - parseInt(r[4]);
            if(D < 0){
                D = D + daysOFMonth; //借一个月
                M--;
            }
            if(M<0){  // 借一年 12个月
                Y--;
                M = M + 12; //
            }
    
            if(Y<0){
                returnStr = "出生日期有误！";
    
            }else if(Y===0){
                if(M===0){
                    returnStr = D+"D";
                }else{
                    returnStr = M+"M";
                }
            }else{
                if(M===0){
                    returnStr = Y+"Y";
                }else{
                    returnStr = Y+"Y"+M+"M";
                }
            }
    
        }
    
        return returnStr;
    },

    getOSType() {
        var userAgent = navigator.userAgent;
        if (userAgent.indexOf("Windows") > -1) {
            return "windows"
        } else if (userAgent.indexOf("Mac") > -1) {
            return "Mac"
        } else if (userAgent.indexOf("Linux") > -1) {
            return "Linux"
        } else if (userAgent.indexOf("Android") > -1) {
            return "Android"
        } else if (userAgent.indexOf("iOS") > -1) {
           return "iOS"
        } else {
            return "other"
        }
      },
    isURL(str) {
        const regex = /^(http|https):\/\/[^ "]+$/;
        return regex.test(str);
    },
      getUrlParam(url)
	{
	  const params = {};
	  const queryString = url.split('?')[1];
	 
	  if (queryString) {
			 const keyValuePairs = queryString.split('&');
	 
			 keyValuePairs.forEach(pair => {
			   const [key, value] = pair.split('=');
			   params[key] = decodeURIComponent(value);
			 });
	  }
	
	  return params
	
	},
	 getUrlParamValue(url,p1)
	 {
	   const params = {};
	   const queryString = url.split('?')[1];
	  
	   if (queryString) {
		 const keyValuePairs = queryString.split('&');
	  
		 keyValuePairs.forEach(pair => {
		   const [key, value] = pair.split('=');
		   params[key] = decodeURIComponent(value);
		 });
	   }

		return params[p1]

	 },

      getBaseName(url)
      {
        let pathArray = url.split("/");
 
        // 获取数组的最后一个元素，即文件名
        let fileName = pathArray[pathArray.length - 1];
        
        return fileName
      },

      
      getPath(url)
      {
        let pathArray = url.split("/");
        // 获取数组的最后一个元素，即文件名
        pathArray.splice(pathArray.length - 1,1);

        return pathArray.join("/")
      },

      getBaseExtName(url)
      {
        let pathArray = MYTOOL.getBaseName(url).split(".");
        if(pathArray.length <= 1)
            return ""
        // 获取数组的最后一个元素，即文件名
        let _name = pathArray[pathArray.length - 1];
        
        return _name
      }

    // setCookie(name, value, expiredays) {//设置时间为天为过期单位
    //     let exdate = new Date();
    //     exdate.setDate(exdate.getDate() + expiredays);
    //     document.cookie = name + '=' + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString())
    // },

    // getCookie(name) {
    //     console.log("????",document.cookie)
    //     let start = document.cookie.indexOf(name + '=')
    //     let end = document.cookie.indexOf(';', start)
    //     if (end == -1) {
    //         end = document.cookie.length
    //     }
    //     return unescape(document.cookie.substring(start, end))
    // }


};

export default MYTOOL
